import { Link } from 'gatsby';
import styled from 'styled-components';
import { deviceMax } from '../../styles/MediaQuery';
import {
    blue,
    blueDark,
    darkGreen,
    greyBorder,
    greyText,
    lightGray,
    limaBackground,
    white,
} from '../../styles/Variables';

export const Filter = styled.div`
    background: linear-gradient(to right, ${darkGreen} 0%, ${limaBackground} 100%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
`;

export const FilterResults = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    * {
        color: white;
        font-weight: 600;
        text-transform: uppercase;
    }
    padding-left: 0.9375rem;

    @media ${deviceMax.tablet} {
        padding-top: 1.25rem;
        padding-bottom: 0.9375rem;
    }
`;

export const FilterResultsNumber = styled.p`
    font-size: 2.375rem;
    margin-top: 0.75rem;
    margin-bottom: 0;

    @media ${deviceMax.tablet} {
        margin: 0;
    }
`;

export const FilterResultsText = styled.p`
    margin: 6px 0px 0px 0.625rem;

    @media ${deviceMax.tablet} {
        margin: 0px 0px 6px 0.625rem;
    }
`;

export const InputSearchWithIconWrapper = styled.div`
    width: calc(90% - 200px);
    padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
    border-right: 2px solid ${white};

    @media ${deviceMax.tablet} {
        width: 100%;
        border-right: unset;
        border-bottom: 2px solid ${white};
    }
`;

export const InputSearchWithIcon = styled.div`
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    width: 100%;
`;

export const InputSearch = styled.input`
    box-sizing: border-box;
    border: 1px solid ${lightGray};
    outline: none;
    padding-left: 0.8rem;
    padding-right: 2.5em;
    padding-top: 0.1em;
    border-radius: 5px;
    width: 100%;
    height: 2.5rem;
    line-height: 25px;
    font-family: Arial, Helvetica, sans-serif;
`;

export const ProductSearch = styled.div`
    width: 100%;
    margin-top: 30px;
`;

export const ProductSearchTitle = styled.p`
    margin: 0;
    color: ${blue};
    font-size: 1.5rem;
    font-weight: 700;
    font-family: 'NeueHelveticaPro57Condensed', Helvetica, Arial;
`;

export const ProductSearchBox = styled.div`
    background: linear-gradient(to right, ${darkGreen} 0%, ${limaBackground} 100%);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 0.4rem;

    * {
        box-sizing: border-box;
    }
`;

export const ProductSearchCategory = styled.div`
    width: 33%;
    padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
    border-right: 2px solid ${white};

    @media ${deviceMax.laptop} {
        width: 100%;
        border-right: unset;
    }
`;

export const ProductSearchText = styled.div`
    width: 33%;
    padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
    border-left: 2px solid ${white};

    @media ${deviceMax.laptop} {
        width: 100%;
        border-left: unset;
    }
`;

export const SearchedSites = styled.div`
    margin-top: 35px;
    margin-left: 60px;
    margin-right: 120px;

    @media ${deviceMax.tablet} {
        margin: 0 0.5rem;
    }
`;

export const SiteContainer = styled.div`
    display: flex;

    @media ${deviceMax.tablet} {
        flex-wrap: wrap;
    }
`;

export const SiteDateWrapper = styled.div`
    margin-right: 1rem;
    margin-bottom: 0.3rem;
`;

export const SiteDate = styled.p`
    font-weight: 700;
    line-height: 1.375rem;
    color: ${greyText};
    margin: 0;
`;

export const SiteTextWrapper = styled.div`
    padding-bottom: 14px;
    margin-bottom: 30px;
    border-bottom: 1px solid ${greyBorder};
`;

export const SiteTitle = styled(Link)`
    font-size: 24px;
    line-height: 28px;
    color: ${blue};
    font-weight: 700;
`;

export const SiteSubtitle = styled.p`
    font-size: 0.875rem;
    color: ${greyText};
    font-family: Arial, Helvetica;
    margin: 0;
`;

export const SiteText = styled.p`
    font-family: Arial, Helvetica;
    font-size: 0.875rem;
    line-height: 1.45;
    margin-top: 0.4rem;
    margin-bottom: 0;
`;

export const SiteTextInjected = styled.div`
    font-family: Arial, Helvetica;
    font-size: 0.875rem;
    line-height: 1.45;
    margin-top: 0.4rem;
    margin-bottom: 0;

    * {
        font-family: Arial, Helvetica;
        font-size: 0.875rem;
        line-height: 1.45;
    }

    em {
        color: ${blue};
        font-style: normal;
    }
`;

export const SendButtonWrapper = styled.div`
    width: 100%;
    margin-bottom: 4.5rem;
`;

export const HighlightedText = styled.span`
    em {
        color: ${blue};
        font-style: normal;
    }
`;

export const HighlightedHeader = styled.span`
    font-size: 24px;
    line-height: 28px;
    color: ${blue};
    font-weight: 700;

    em {
        color: ${blueDark};
        font-style: normal;
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
    }

    span {
        font-size: 24px;
        line-height: 28px;
        color: ${blue};
        font-weight: 700;
    }
`;
